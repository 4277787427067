<template>
  <div class="about pa-5">
    <h1>{{$t('common.welcome')}}</h1>
  </div>
</template>


<script>
export default {
  name: 'About',
  data() {
    return {
     
};
  },

  methods: {},

  mounted() {
   },

  watch: {},
};
</script>